<template>
    <div>
        <nav-bar v-on:toggleNavCart="toggleOpenClose"></nav-bar>
        <div v-if="showResturantDetail">
            <div class="vendor-details-main" id="reshead"
                :class="$auth.setting && !($auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0) ? 'menu-details cust-menu-list' : 'single-menu-details'">
                <div class="container-fluid"
                    v-if="$auth.setting && !($auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0)">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="vendor-left-main d-flex cust-vendor-main res-items-main mb-3">
                                <div class="res-items-grid resturant-head">
                                    <div class="res-items-left">
                                        <div class="resimg-flex">
                                            <div class="img-cls resimg-squre" :class="scheduleClass">
                                                <skeleton-loader-vue v-if="!resturantDetail.icon_image" width="120px"
                                                    height="120px" style="border-radius: 5px;" />
                                                <img v-else :src="resturantDetail.icon_image" alt="..."
                                                    class="itmimg" />
                                            </div>
                                            <div class="resimg-data">
                                                <h3 class="mt-0" v-if="!resturantDetail.name">
                                                    <skeleton-loader-vue type="string" width="100px" height="20px"
                                                        style="bottom: 11px" />
                                                </h3>
                                                <h3 class="mt-0" v-else>{{ resturantDetail.name }}</h3>
                                                <p class="mb-0" v-if="!resturantDetail.Address"><skeleton-loader-vue
                                                        type="string" width="100px" height="20px" /></p>
                                                <p class="mb-0">{{ resturantDetail.Address }}</p>
                                                <div class="info-view" v-if="!resturantDetail.restaurant_id">
                                                    <skeleton-loader-vue type="string" class="skel_view" width="25%"
                                                        height="31px" />
                                                </div>
                                                <div class="desktop--view" v-if="!is_scrren_hide && resturantDetail">
                                                    <view-info v-if="resturantDetail.restaurant_id"
                                                        :restaurantID="resturantDetail.restaurant_id"></view-info>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mobile--view mobile-viewinfo"
                                            v-if="is_scrren_hide && resturantDetail">
                                            <div class="res-items-right">
                                                <div class="marquee-btn-main viewinfo-new text-end">
                                                    <div class="rstinfo-cls rstinfo-cls-box deliveryfees-main">
                                                        <div class="">
                                                            <div class="restinfo-box" v-if="is_rating">
                                                                <div class="review-cnt">
                                                                    <div v-if="resturantDetail.rating != 0 && is_rating"
                                                                        class="star-rate"
                                                                        :class="(parseFloat(resturantDetail.rating).toFixed(1) > 2.0 || parseFloat(resturantDetail.rating).toFixed(1) == 0.0 ? 'green-cls' : 'red-cls')">
                                                                        <i class="fas fa-star me-2"></i>
                                                                        <span v-if="resturantDetail.rating == 0">{{
                                                                            parseFloat(resturantDetail.rating).toFixed(1)
                                                                        }}</span>
                                                                        <span v-else>{{
                                                                            parseFloat(resturantDetail.rating).toFixed(1)
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="restinfo-box mt-2">
                                                                <view-info v-if="resturantDetail.restaurant_id"
                                                                    :restaurantID="resturantDetail.restaurant_id"></view-info>
                                                            </div>
                                                        </div>
                                                        <div class="restinfo-box">
                                                            <div class="delivery-fees-nw text-center"
                                                                v-if="resturantDetail && resturantDetail.delivery_time != 0">
                                                                <p class="mb-0">{{ $t('Delivery in ') }}</p>

                                                                <h5 class="mb-0">{{ resturantDetail.delivery_time }}
                                                                </h5>
                                                            </div>
                                                        </div>

                                                        <div class="restinfo-box">
                                                            <div class="delivery-minutes text-center"
                                                                v-if="is_twoPersonPrice && resturantDetail && resturantDetail.two_person_price != 0">
                                                                <p class="mb-0">{{ $t('Value for Two') }}</p>
                                                                <h5 class="mb-0"
                                                                    v-if="resturantDetail.two_person_price">
                                                                    <span v-if="$auth.setting.currency"
                                                                        v-html="$auth.setting.currency"
                                                                        class="currency"></span>{{
                                                                            parseFloat(resturantDetail.two_person_price).toFixed(1)
                                                                        }}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column align-items-end flex-grow-1">
                                        <div class="res-items-right desktop--view"
                                            v-if="!is_scrren_hide && resturantDetail">
                                            <div class="marquee-btn-main viewinfo-new text-end">
                                                <div class="rstinfo-cls rstinfo-cls-box deliveryfees-main">
                                                    <div class="restinfo-box">
                                                        <div class="delivery-fees-nw text-center"
                                                            v-if="resturantDetail && resturantDetail.delivery_time != 0">
                                                            <p class="mb-0">{{ $t('Delivery in ') }}</p>
                                                            <h5 class="mb-0">{{ resturantDetail.delivery_time }}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="restinfo-box" v-if="is_rating">
                                                        <div class="review-cnt">
                                                            <div v-if="resturantDetail.rating != 0 && is_rating"
                                                                class="star-rate"
                                                                :class="(parseFloat(resturantDetail.rating).toFixed(1) > 2.0 || parseFloat(resturantDetail.rating).toFixed(1) == 0.0 ? 'green-cls' : 'red-cls')">
                                                                <i class="fas fa-star me-2"></i>
                                                                <span v-if="resturantDetail.rating == 0">{{
                                                                    parseFloat(resturantDetail.rating).toFixed(1)
                                                                }}</span>
                                                                <span v-else>{{
                                                                    parseFloat(resturantDetail.rating).toFixed(1)
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="restinfo-box"
                                                        v-if="is_twoPersonPrice && resturantDetail && resturantDetail.two_person_price != 0">
                                                        <div class="delivery-minutes text-center">
                                                            <p class="mb-0">{{ $t('Value for Two') }}</p>
                                                            <h5 class="mb-0" v-if="resturantDetail.two_person_price">
                                                                <span v-if="$auth.setting.currency"
                                                                    v-html="$auth.setting.currency"
                                                                    class="currency"></span>{{
                                                                        parseFloat(resturantDetail.two_person_price).toFixed(1)
                                                                    }}
                                                            </h5>
                                                        </div>
                                                    </div>

                                                    <!-- <div v-if="b64Decode(resturantDecode)"> -->
                                                    <div v-if="shareLink && shareLink != ''">
                                                        <button class="share-icon mt-3" @click="shareButton()">
                                                            <img src="@/assets/images/share-icon.png" alt="offer"
                                                                height="50" width="50">
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="searchbar-item mt-3">
                                            <input type="search" v-model="search" name="search" class="srch-itm"
                                                :placeholder="$t('Search')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <cart v-if="is_cart_show" ref="cartToggle" class="cart-detil"></cart>
            </div>
            <div class="resturant-menu-main">
                <div class="container-fluid">
                    <div class="row resturant-menu-row">
                        <div class="col pe-lg-0">
                            <item-list :restaurant_on_off="resturantDetail.restaurant_on_off"
                                v-if="resturantDetail && resturantDetail.restaurant_id"
                                :restautantDetails="resturantDetail" :search="search" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="noDetails">
            <div class="no-cont-cls">
                <p>{{ $t('Uh oh') }}.</p>
                <p>{{ $t('This page doesn`t seem to exist') }}</p>
                <button class="back-btn btn go-cls " @click="goToHome()">{{ $t('Add Item') }}</button>
            </div>
        </div>
        <div class="mobile-cart full-btn mobile-cart-main" v-if="cart.length > '0'">
            <a v-if="!is_btn_spinner" href="javascript:void(0)"
                class="mb-view-cart green-btn d-flex justify-content-between" @click="minimunOrderValue()">
                <div>
                    {{cart.reduce((a, c) => (a + c.quantity), 0)}} {{cart.reduce((a, c) => (a + c.quantity), 0) > 1 ?
                        $t('Items') : $t('Item')}} |
                    <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                        getTotalAmount().toFixed(2)
                    }}
                </div><span>View Cart</span>
            </a>
            <b-button v-else variant="primary" class="mb-view-cart green-btn d-flex justify-content-between" disabled>
                <b-spinner small type="grow"></b-spinner>{{ $t('Loading') }}...
            </b-button>
        </div>
        <!--Alert Modal start-->
        <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
        <!--Alert Modal End-->
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Cart from "@/components/Cart.vue";
import Rating from "@/components/Rating.vue";
import ItemList from "@/components/ItemList.vue";
import ViewInfo from "@/components/ViewInfo.vue";
import Footer from '../components/Footer.vue';
import { mapActions, mapGetters, mapState } from "vuex";
import VueSkeletonLoader from 'skeleton-loader-vue';
import AlertModal from '../components/AlertModal.vue';

export default {
    name: "itemDetail",
    components: { NavBar, Cart, Rating, ItemList, ViewInfo, Footer, 'skeleton-loader-vue': VueSkeletonLoader, AlertModal },
    data() {
        return {
            resturantDetail: {},
            showResturantDetail: true,
            is_rating: false,
            is_twoPersonPrice: false,
            is_minimuOrder: false,
            scheduleClass: '',
            is_cart_show: false,
            is_scrren_hide: (window.screen.width < 1280 ? true : false),
            search: '',
            is_btn_spinner: false,
            isAlert: false,
            resturantDecode: null,
            shareLink: '',
        };
    },
    computed: {
        ...mapGetters("order", ["isPickupDelivery"]),
        ...mapGetters("product", ["cart"]),
    },
    mounted() {
        setTimeout(() => {
            this.getRestaurantDetail();
        }, 1000)
        // this.getRestaurantDetail();
        setTimeout(() => this.resturantDetail.rating > 0 ? this.is_rating = true : '', 2000);
        setTimeout(() => this.is_twoPersonPrice = true, 2000);
        setTimeout(() => this.is_minimuOrder = true, 2000);

    },
    methods: {
        ...mapActions("restaurant", ["getRestaurantBySlug", "getRestaurantDetails"]),

        b64Encode(str) {
            const utf8Bytes = unescape(encodeURIComponent(str));
            // Use window.btoa to encode the UTF-8 bytes
            return window.btoa(utf8Bytes);
        },

        // b64Decode(str) {
        //   try {        
        //     // Base64 decode the string
        //     return decodeURIComponent(atob(str));
        //   } catch (error) {
        //     // Log the error and return a default or empty value
        //     return null; // or any other fallback value
        //   }
        // },

        // b64Decode(base64String) {
        //   try {
        //     // Add padding if necessary (Base64 strings should be divisible by 4)
        //     if (base64String) {
        //       const padding = base64String.length % 4;
        //       if (padding > 0) {
        //         base64String += '='.repeat(4 - padding);
        //       }
        //     }

        //     // Attempt to decode the Base64 string
        //       return decodeURIComponent(
        //         Array.prototype.map.call(atob(base64String), (c) =>
        //           '%' + c.charCodeAt(0).toString(16).padStart(2, '0')
        //         ).join('')
        //       );
        //     } catch (error) {
        //       return null; // Return null or a default value to handle the error
        //   }
        // },


        // b64Decode(str) {
        //   try {
        //     // Trim whitespace and replace URL-encoded spaces (%20) with actual spaces
        //     // const base64Str = str.replace(/\s+/g, '');
        //     // if (base64Str) {
        //     //   return decodeURIComponent(escape(str));
        //     // } else {
        //     //   return decodeURIComponent(atob(base64Str));
        //     // }
        //      const utf8Bytes = atob(decodeURIComponent(str));
        //      if(utf8Bytes){}
        //   // Use window.btoa to encode the UTF-8 bytes
        //   return window.atob(utf8Bytes);
        //   } catch (error) {
        //     // Log the error and return a default or empty value
        //     return null; // or any other fallback value
        //   }
        // },

        shareButton() {
            const restaurantIDParam = this.$route.params.restaurantid;
            const host = window.location.origin;
            //const urlWithParams = `${host}/restaurant/${restaurantIDParam}`;
            navigator.clipboard.writeText(this.shareLink)
                .then(() => {
                    this.$swal({
                        title: this.$auth.setting.app_name,
                        html: "Please click 'OK' to redirect and copy the link.",
                        allowOutsideClick: false
                    }).then(() => {
                        // Redirect after the user clicks "OK"          
                        // window.location.href = urlWithParams;
                    });
                })
                .catch(err => {
                    console.error("Failed to copy URL: ", err);
                });
        },



        async getRestaurantDetail() {
            if (this.$route.params.restaurant_name) {
                this.getRestaurantBySlug({
                    vendor_id: this.$auth.getVendorId(),
                    slug: this.$route.params.restaurant_name,
                    is_langauge: this.$store.state.lang.locale,
                    delivery_type_time_slots: this.isPickupDelivery,
                    restaurant_id: this.$route.params && this.$route.params.restaurantid && this.$route.params.restaurantid != "" ? this.$route.params.restaurantid : 0,
                }).then((data) => {
                    if (data.code == 200) {
                        this.resturantDetail = data.Result;
                        this.$auth.setRestauranID(data.Result.restaurant_id);
                        this.is_cart_show = true;
                        this.showResturantDetail = true;
                        //Add Class Schedule or Closed
                        if (data.Result.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 0) {
                            this.scheduleClass = 'closed';
                        } else if (data.Result.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 1) {
                            this.scheduleClass = 'Schedule';
                        }
                    } else {
                        this.showResturantDetail = false;
                    }

                });
            } else {
                this.latitude = localStorage.getItem('latitude');
                this.longitude = localStorage.getItem('longitude');
                let restaurantIDParam = this.$route.params.restaurantid
                this.resturantDecode = window.atob(restaurantIDParam);
                await this.getRestaurantDetails({
                    vendor_id: this.$auth.getVendorId(),
                    restaurant_id: this.resturantDecode,
                    is_langauge: this.$store.state.lang.locale,
                    latitude: parseFloat(localStorage.getItem("latitude")),
                    longitude: parseFloat(localStorage.getItem("longitude"))
                }).then((data) => {
                    if (data.code == 200) {
                        this.shareLink = data.Result && data.Result.share_link ? data.Result.share_link : '';
                        this.getRestaurantBySlug({
                            vendor_id: this.$auth.getVendorId(),
                            // slug: this.$route.params.restaurant_name,
                            slug: data.Result.slug,
                            is_langauge: this.$store.state.lang.locale,
                            delivery_type_time_slots: this.isPickupDelivery,
                            latitude: this.latitude,
                            longitude: this.longitude,
                            restaurant_id: this.$route.params && this.$route.params.restaurantid && this.$route.params.restaurantid != "" ? this.$route.params.restaurantid : 0,
                        }).then((data) => {
                            if (data.code == 200) {
                                this.resturantDetail = data.Result;
                                localStorage.setItem('storelat', this.resturantDetail.latitude)
                                localStorage.setItem('storelng', this.resturantDetail.longitude)
                                this.$auth.setRestauranID(data.Result.restaurant_id);
                                this.is_cart_show = true;
                                this.showResturantDetail = true;
                                //Add Class Schedule or Closed
                                if (data.Result.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 0) {
                                    this.scheduleClass = 'closed';
                                } else if (data.Result.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 1) {
                                    this.scheduleClass = 'Schedule';
                                }
                            } else {
                                this.showResturantDetail = false;
                            }
                        });
                    }
                })
            }
        },

        // getRestaurantDetail() {
        //   this.getRestaurantBySlug({
        //     vendor_id: this.$auth.getVendorId(),
        //     slug: this.$route.params.restaurant_name,
        //     is_langauge: this.$store.state.lang.locale,
        //     delivery_type_time_slots: this.isPickupDelivery,
        //   }).then((data) => {
        //     if (data.code == 200) {
        //       this.resturantDetail = data.Result;
        //       this.$auth.setRestauranID(data.Result.restaurant_id);
        //       this.is_cart_show = true;
        //       this.showResturantDetail = true;
        //       //Add Class Schedule or Closed
        //       if (data.Result.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 0) {
        //         this.scheduleClass = 'closed';
        //       } else if (data.Result.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 1) {
        //         this.scheduleClass = 'Schedule';
        //       }
        //     } else {
        //       this.showResturantDetail = false;
        //     }

        //   });
        // },

        goToHome() {
            this.$router.go(-1);
        },

        getTotalAmount() {
            let total = 0;
            this.cart.forEach(item => {
                total += (item.price * item.quantity);
            });
            return total;
        },

        toggleOpenClose() {
            this.$emit("toggleCartOpen", 'yes');
            this.$refs.cartToggle.cartOpenModal();
        },

        minimunOrderValue() {
            this.is_btn_spinner = true;
            if (!this.cart.length) {
                alert(this.$t("Please add Item in to cart"));
                this.is_btn_spinner = false;
                return false;
            }
            var restaurantId = this.cart[0].restaurant_id;
            this.getRestaurantDetails({
                vendor_id: this.$auth.getVendorId(),
                restaurant_id: restaurantId,
                is_langauge: this.$store.state.lang.locale,
            }).then((data) => {
                if (data.code == 200) {
                    if (this.$auth.user) {
                        if (parseFloat(this.getTotalAmount().toFixed(2)) >= parseFloat(data.Result.minimum_order_value)) {
                            this.is_btn_spinner = false;
                            this.$router.push({ name: "review-cart" });
                        } else {
                            let currency = '';
                            if (document.getElementById('currency') && document.getElementById('currency').childNodes[0]) {
                                currency = document.getElementById('currency').childNodes[0].textContent;
                            }
                            this.AlertData = {
                                displayMessage: this.$t('Minimum items total must be ') + currency + data.Result.minimum_order_value,
                                isSetClass: 'cart-modal-cls'
                            };
                            this.isAlert = true;
                            this.is_btn_spinner = false;
                            window.$(this.$refs.AlertModal).modal();
                        }
                    } else {
                        this.is_btn_spinner = false;
                        this.$router.push({ name: "sign-in" });
                    }
                }
            });
        },

        onModalCloseFun(value) {
            this.isAlert = false;
        },
    },
};
</script>
<style>
.skel_view {
    border-radius: 30px !important;
    padding: 5px 10px !important;
    margin-top: 15px;
    background: rgba(0, 0, 0, 0.12);
    width: 25% !important;
    height: 31px !important;
}

.share-icon {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white
}
</style>